import { createApp, h } from "vue";
import router from "./router";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import Card from "primevue/card";
import Listbox from 'primevue/listbox';
import Column from "primevue/column";
import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import MegaMenu from "primevue/megamenu";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Message from "primevue/message";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Ripple from "primevue/ripple";
import ScrollPanel from "primevue/scrollpanel";
import ScrollTop from "primevue/scrolltop";
import Sidebar from "primevue/sidebar";
import StyleClass from "primevue/styleclass";
import TieredMenu from "primevue/tieredmenu";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Toolbar from "primevue/toolbar";
import Tooltip from "primevue/tooltip";
import ToggleButton from "primevue/togglebutton";
import CodeHighlight from "./AppCodeHighlight";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import Access from "./pages/Access";
import Error from "./pages/Error";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Wizard from "./pages/Wizard";
import { createPinia } from "pinia";
import ConfirmationService from "primevue/confirmationservice";

const app = createApp({
  computed: {
    ViewComponent() {
      switch (this.$route.path) {
        case "/login":
          return Login;
        case "/error":
          return Error;
        case "/access":
          return Access;
        case "/notfound":
          return NotFound;
        case "/wizard":
          return Wizard;
        default:
          return App;
      }
    },
  },
  render() {
    return h(this.ViewComponent);
  },
});
app.use(PrimeVue, {
  ripple: true,
  locale: {
    dayNames: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    monthNames: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthNamesShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    firstDayOfWeek: 1,
  },
});
app.use(ToastService);
app.use(router);
const pinia = createPinia();
app.use(pinia);
app.directive("tooltip", Tooltip);
app.directive("ripple", Ripple);
app.directive("code", CodeHighlight);
app.directive("styleclass", StyleClass);
app.component("Button", Button);
app.component("Card", Card);
app.component("Listbox", Listbox);
app.component("Column", Column);
app.component("ContextMenu", ContextMenu);
app.component("DataTable", DataTable);
app.component("Dialog", Dialog);
app.component("Dropdown", Dropdown);
app.component("InputNumber", InputNumber);
app.component("InputText", InputText);
app.component("AutoComplete", AutoComplete);
app.component("MegaMenu", MegaMenu);
app.component("Menu", Menu);
app.component("Menubar", Menubar);
app.component("Message", Message);
app.component("Paginator", Paginator);
app.component("Panel", Panel);
app.component("PanelMenu", PanelMenu);
app.component("ScrollPanel", ScrollPanel);
app.component("ScrollTop", ScrollTop);
app.component("Sidebar", Sidebar);
app.component("TieredMenu", TieredMenu);
app.component("Toast", Toast);
app.component("Toolbar", Toolbar);
app.component("ToggleButton", ToggleButton);
app.use(ConfirmationService);
app.mount("#app");
