export const persistUserData = (userData) => {
  window.localStorage.userData = JSON.stringify(userData);
};

export const readUserData = () => {
  if (window.localStorage.userData) {
    return JSON.parse(window.localStorage.userData);
  }
  return {};
};

export const removeUserData = () => {
  delete window.localStorage.userData;
};
