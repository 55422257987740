import axios from "axios";

export const login = (email, password) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/users/auth`, {
    email,
    password,
  });
};

export const changePassword = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/users/change-password`,
    payload
  );
};
