import axios from "axios";
import { readUserData } from "@/services/user";
const user = readUserData();

export default axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    authorization: `${user.token}`,
  },
});
