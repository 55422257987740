import { createRouter, createWebHashHistory } from "vue-router";
import { readUserData, removeUserData } from "@/services/user";
import { checkToken } from "@/api/user";

const checkAuth = async (to, from, next) => {
  const userStored = readUserData();
  if (!userStored.token) {
    next("/login");
    return;
  }
  try {
    await checkToken();
    next();
  } catch (err) {
    removeUserData();
    next("/#/login");
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    exact: true,
    component: () => import("./components/Home"),
    meta: {
      breadcrumb: [{ label: "Home" }],
    },
    beforeEnter: checkAuth,
  },
  {
    path: "/config",
    name: "Configuración",
    exact: true,
    component: () => import("./components/config/Config.vue"),
    meta: {
      breadcrumb: [{ label: "Configuración" }],
    },
    beforeEnter: checkAuth,
  },
  {
    path: "/shipments",
    name: "Envíos",
    exact: true,
    component: () => import("./components/shipments/Shipments.vue"),
    meta: {
      breadcrumb: [{ label: "Envíos" }],
    },
    beforeEnter: checkAuth,
  },
  {
    path: "/newshipment/:step",
    name: "NewShipment",
    exact: true,
    component: () => import("./components/shipments/NewShipment"),
    meta: {
      breadcrumb: [{ label: "Crear Envío" }],
    },
    beforeEnter: checkAuth,
  },
  {
    path: "/quoter",
    name: "Cotizador",
    exact: true,
    component: () => import("./components/Quoter.vue"),
    meta: {
      breadcrumb: [{ label: "Cotizador" }],
    },
    beforeEnter: checkAuth,
  },
  {
    path: "/support",
    name: "Soporte",
    exact: true,
    component: () => import("./components/Support/Support.vue"),
    meta: {
      breadcrumb: [{ label: "Soporte" }],
    },
    beforeEnter: checkAuth,
  },
  {
    path: "/billing",
    name: "Facturación",
    exact: true,
    component: () => import("./components/Billing.vue"),
    meta: {
      breadcrumb: [{ label: "Facturación" }],
    },
    beforeEnter: checkAuth,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    component: () => import("./components/Dashboard"),
    meta: {
      breadcrumb: [{ label: "Dashboard" }],
    },
    beforeEnter: checkAuth,
  },
  {
    path: "/shipment-detail",
    name: "Shipment Detail",
    exact: true,
    component: () => import("./components/ShipmentDetail"),
    meta: {
      breadcrumb: [{ label: "Detalle del envío" }],
    },
    beforeEnter: checkAuth,
  },
  {
    path: "/manifets",
    name: "Manifiestos",
    exact: true,
    component: () => import("./components/manifests"),
    meta: {
      breadcrumb: [{ label: "Manifiestos" }],
    },
    beforeEnter: checkAuth,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

export default router;
