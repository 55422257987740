<template>
  <div
    class="layout-topbar clearfix"
    style="display: grid; grid-template-columns: 1fr 1fr 1fr"
  >
    <button class="layout-topbar-logo p-link" @click="goDashboard">
      <img
        id="layout-topbar-logo"
        alt="Analisis de Crédito"
        src="layout/images/logo-full.png"
      />
    </button>

    <button class="layout-menu-button p-link" @click="onMenuButtonClick">
      <i class="pi pi-bars"></i>
    </button>

    <button
      id="topbar-menu-button"
      class="p-link"
      @click="onTopbarMenuButtonClick"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <div class="search">
      <div class="input-group">
        <InputText
          ref="inputText"
          style="width: 30rem; height: 3rem"
          v-model="searchedText"
          @input="search"
          @keydown.enter="filterShipments"
          placeholder="Búsqueda general"
        />

        <Listbox
          v-if="showHistoryCard && items.length > 0"
          class="search-history-card"
          :style="{
            width: '30rem',
            position: 'absolute',
            top: '100%',
            zIndex: 1000,
            overflowY: 'auto',
            maxHeight: '10rem',
          }"
          v-model="selectedHistoryItem"
          :options="formattedItems"
          optionLabel="label"
          @change="selectHistoryItem"
        />
      </div>
      <Button icon="pi pi-chevron-down" @click="toggleHistoryCard" />
    </div>

    <ul :class="topbarItemsClass">
      <li
        v-if="profileMode === 'popup' || horizontal"
        :class="[
          'user-profile',
          { 'active-topmenuitem': activeTopbarItem === 'profile' },
        ]"
        @click="
          $emit('topbar-item-click', { originalEvent: $event, item: 'profile' })
        "
      >
        <button class="p-link" style="margin-bottom: 1rem">
          <i class="topbar-icon pi pi-user"></i>
          <span class="topbar-item-name">Arlene Welch</span>
        </button>

        <transition name="layout-submenu-container">
          <ul class="fadeInDown" v-show="activeTopbarItem === 'profile'">
            <li role="menuitem">
              <button class="p-link">
                <i class="pi pi-user"></i>
                <span>{{ user.name }}</span>
              </button>
            </li>
            <li role="menuitem">
              <button class="p-link" @click="logout">
                <i class="pi pi-sign-out"></i>
                <span>Cerrar sesión</span>
              </button>
            </li>
          </ul>
        </transition>
      </li>

      <li
        :class="[{ 'active-topmenuitem': activeTopbarItem === 'settings' }]"
        @click="
          $emit('topbar-item-click', {
            originalEvent: $event,
            item: 'settings',
          })
        "
      >
        <transition name="layout-submenu-container">
          <ul class="fadeInDown" v-show="activeTopbarItem === 'settings'">
            <li role="menuitem">
              <button class="p-link">
                <i class="pi pi-tags"></i>
                <span>Pending tasks</span>
                <span class="topbar-submenuitem-badge">6</span>
              </button>
            </li>
            <li role="menuitem">
              <button class="p-link">
                <i class="pi pi-calendar-plus"></i>
                <span>Meeting today at 3pm</span>
              </button>
            </li>
            <li role="menuitem">
              <button class="p-link">
                <i class="pi pi-download"></i>
                <span>Download</span>
              </button>
            </li>
            <li role="menuitem">
              <button class="p-link">
                <i class="pi pi-lock"></i>
                <span>Book flight</span>
              </button>
            </li>
          </ul>
        </transition>
      </li>

      <li
        :class="[{ 'active-topmenuitem': activeTopbarItem === 'messages' }]"
        @click="
          $emit('topbar-item-click', {
            originalEvent: $event,
            item: 'messages',
          })
        "
      >
        <transition name="layout-submenu-container">
          <ul class="fadeInDown" v-show="activeTopbarItem === 'messages'">
            <li role="menuitem">
              <button class="topbar-message p-link">
                <img src="layout/images/avatar-john.png" alt="babylon-layout" />
                <span>Give me a call</span>
              </button>
            </li>
            <li role="menuitem">
              <button class="topbar-message p-link">
                <img
                  src="layout/images/avatar-julia.png"
                  alt="babylon-layout"
                />
                <span>Reports attached</span>
              </button>
            </li>
            <li role="menuitem">
              <button class="topbar-message p-link">
                <img
                  src="layout/images/avatar-kevin.png"
                  alt="babylon-layout"
                />
                <span>About your invoice</span>
              </button>
            </li>
            <li role="menuitem">
              <button class="topbar-message p-link">
                <img
                  src="layout/images/avatar-julia.png"
                  alt="babylon-layout"
                />
                <span>Meeting today</span>
              </button>
            </li>
            <li role="menuitem">
              <button class="p-link">
                <i class="topbar-icon pi pi-person"></i>
                <span>Out of office</span>
              </button>
            </li>
          </ul>
        </transition>
      </li>

      <li
        :class="[
          { 'active-topmenuitem': activeTopbarItem === 'notifications' },
        ]"
        @click="
          $emit('topbar-item-click', {
            originalEvent: $event,
            item: 'notifications',
          })
        "
      >
        <transition name="layout-submenu-container">
          <ul class="fadeInDown" v-show="activeTopbarItem === 'notifications'">
            <li role="menuitem">
              <button class="p-link">
                <i class="pi pi-pencil"></i>
                <span>Change Theme</span>
                <span class="topbar-submenuitem-badge">4</span>
              </button>
            </li>
            <li role="menuitem">
              <button class="p-link">
                <i class="pi pi-star"></i>
                <span>Favorites</span>
              </button>
            </li>
            <li role="menuitem">
              <button class="p-link">
                <i class="pi pi-lock"></i>
                <span>Lock Screen</span>
                <span class="topbar-submenuitem-badge">2</span>
              </button>
            </li>
            <li role="menuitem">
              <button class="p-link">
                <i class="pi pi-image"></i>
                <span>Wallpaper</span>
              </button>
            </li>
          </ul>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import { userStore } from "@/stores/user";
import { removeUserData, readUserData } from "@/services/user";
import router from "@/router";

export default {
  props: {
    topbarMenuActive: Boolean,
    activeTopbarItem: String,
    profileMode: String,
    horizontal: Boolean,
  },
  data: function () {
    return {
      user: readUserData(),
      searchedText: "",
      items: JSON.parse(localStorage.getItem("lastSearches") || "[]"),
      showHistoryCard: false,
      selectedHistoryItem: null,
    };
  },
  methods: {
    onMenuButtonClick(event) {
      this.$emit("menubutton-click", event);
    },
    onTopbarMenuButtonClick(event) {
      this.$emit("topbar-menubutton-click", event);
    },
    goDashboard() {
      window.location = "/#/";
    },
    selectHistoryItem() {
      if (this.selectedHistoryItem && this.selectedHistoryItem.label) {
        this.searchedText = this.selectedHistoryItem.label;
        this.filterShipments();
      }
      this.showHistoryCard = false;
    },
    toggleHistoryCard() {
      this.showHistoryCard = !this.showHistoryCard;
    },
    logout() {
      const storedUser = userStore();
      storedUser.setUser({});
      removeUserData();
      router.push({ path: "/login" });
    },
    filterShipments() {
      if (this.searchedText.trim() === "") {
        return;
      }

      this.items.unshift({ label: this.searchedText });

      this.items = this.items.slice(0, 10);

      localStorage.setItem("lastSearches", JSON.stringify(this.items));

      router.push(`/shipments/?searchedText=${this.searchedText}`);
      this.searchedText = "";
      this.showHistoryCard = false;
    },
    search() {
      this.items = JSON.parse(localStorage.getItem("lastSearches") || "[]");
    },
  },
  computed: {
    formattedItems() {
      return this.items.map((item) => ({ label: item.label }));
    },
    topbarItemsClass() {
      return [
        "topbar-menu fadeInDown",
        {
          "topbar-menu-visible": this.topbarMenuActive,
        },
      ];
    },
  },
};
</script>

<style scoped>
.search-history-card {
  display: block;
  border: 1px solid #ccc;
  position: absolute;
}
.search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.input-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
