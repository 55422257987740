<template>
  <div class="login-body">
    <div class="login-panel"></div>

    <div class="login-content">
      <img src="layout/images/logo-full.png" alt="Ishiper" />

      <h1><span>Acceso usuarios</span> ishiper</h1>
      <p>Benvenido, ingresa tus credenciales.</p>

      <div class="login-input-wrapper">
        <InputText placeholder="Usuario" v-model="user" />
        <i class="pi pi-user"></i>
      </div>

      <div class="login-input-wrapper">
        <InputText type="password" placeholder="Clave" v-model="pass" />
        <i class="pi pi-lock"></i>
      </div>

      <ProgressSpinner
        class="spinner"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        v-if="loading"
      />

      <Button label="Entrar" @click="send" v-else />

      <Toast position="bottom-center" />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import router from "@/router";
import ProgressSpinner from "primevue/progressspinner";
import { login } from "@/api/auth";
import { persistUserData, readUserData } from "@/services/user";
import { useToast } from "primevue/usetoast";
import { load } from "recaptcha-v3";

const user = ref("");
const pass = ref("");
const loading = ref(false);
const toast = useToast();
const userStored = readUserData();

if (userStored) {
  router.push({ path: "/" });
}

const recaptchaValidation = async () => {
  const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
  const token = await recaptcha.execute("login");
  if (token) return true;
  else return false;
};

const send = async () => {
  loading.value = true;
  const isValid = await recaptchaValidation();
  if (!isValid) return;
  try {
    const resp = await login(user.value.toLocaleLowerCase(), pass.value);
    persistUserData(resp.data);
    window.location.href = "/";
  } catch (err) {
    loading.value = false;
    toast.add({
      severity: "error",
      summary: "Validación de Credenciales",
      detail: "Usuario o Clave incorrectos.",
      life: 7000,
    });
  }
};
</script>

<style scoped>
.spinner {
  width: 50px;
  height: 50px;
  margin-left: 50px;
}
</style>
