import { defineStore } from 'pinia';

export const userStore = defineStore('user', {
    state: () => {
        return { user: {} };
    },
    actions: {
        setUser(user) {
            this.user = user;
        },
    },
});
