<template>
  <div class="layout-footer">
    <div class="grid">
      <div class="col-6">
        <a href="/" class="logo-container">
          <img src="layout/images/logo-full.png" alt="babylon-layout" />
        </a>
      </div>
      <div class="col-6 footer-icons">
        <button class="p-link" @click="openHome">
          <i class="pi pi-home"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
const openHome = () => {
  window.location.href = "/";
};
</script>
